<template>
  <div
    aria-labelledby="company-select-modal"
    aria-modal="true"
    class="fixed inset-0 z-30 overflow-y-auto"
    role="dialog"
  >
    <div class="flex h-screen select-none items-center justify-center">
      <div
        aria-hidden="true"
        class="fixed inset-0 bg-portal-gray-950 bg-opacity-80 transition-opacity"
        @click="closeModal"
      ></div>
      <div
        ref="modalRef"
        class="m-6 flex max-h-[95vh] max-w-4xl flex-grow transform flex-col overflow-y-auto rounded-md bg-white p-6 text-portal-gray-800"
      >
        <div class="mb-6 text-center">
          <h3 class="text-2xl font-bold">Allocate SIMs</h3>
        </div>
        <div class="grid grid-cols-2">
          <div class="relative mx-2 mb-4 flex grow flex-col gap-1">
            <label class="text-lg font-semibold" for="companySearch"> Search Company </label>
            <input
              id="companySearch"
              v-model="companySearch"
              class="form-input block w-full rounded border px-2 py-1"
              type="text"
              @blur="companyHideDropdownAfterDelay"
              @focus="showCompanyDropdown = true"
              @input="selectedCompany ? selectedCompany.name : null"
            />
            <ul
              v-if="showCompanyDropdown"
              class="absolute left-0 top-full z-10 max-h-32 w-full overflow-auto rounded border border-gray-300 bg-white"
            >
              <li
                v-for="company in filteredCompanies"
                :key="company.organizationId"
                class="cursor-pointer px-2 py-1 hover:bg-portal-gray-100"
                @click="selectCompany(company)"
              >
                {{ company.name }}
              </li>
            </ul>
          </div>
          <div class="relative mx-2 mb-4 flex grow flex-col gap-1">
            <label class="text-lg font-semibold" for="carrierSearch"> Select Carrier </label>
            <input
              id="carrierSearch"
              v-model="selectedCarrier"
              autocomplete="off"
              class="form-input block w-full rounded border px-2 py-1"
              type="text"
              @blur="carrierHideDropdownAfterDelay"
              @focus="showCarrierDropdown = true"
              @input="selectedCarrier = ''"
            />
            <ul
              v-if="showCarrierDropdown"
              autocomplete="off"
              class="absolute left-0 top-full z-10 max-h-32 w-full overflow-auto rounded border border-gray-300 bg-white"
            >
              <li
                v-if="tmobileFilteredInternalPlans[0].name != 'No Plans Found'"
                autocomplete="off"
                class="cursor-pointer px-2 py-1 hover:bg-portal-gray-100"
                @click="selectCarrier('T-Mobile')"
              >
                T-Mobile
              </li>
              <li
                v-if="verizonFilteredInternalPlans[0].name != 'No Plans Found'"
                autocomplete="off"
                class="cursor-pointer px-2 py-1 hover:bg-portal-gray-100"
                @click="selectCarrier('Verizon')"
              >
                Verizon
              </li>
            </ul>
          </div>
        </div>
        <div class="mb-4 mt-2 h-[1px] border-t-2 border-portal-gray-700-base" />
        <div>
          <label for="activate">Are you activating a new SIM or SIMs? <br />Check box for yes. </label>
          <input id="activate" v-model="activate" type="checkbox" />
        </div>
        <!-- NOTE New address form -->
        <CheckAddressForm
          v-if="activate && isFWA"
          v-model:address-valid="addressValid"
          v-model:address-data="addressData"
          :organization-id="selectedCompany?.organizationId"
        />
        <div v-if="activate && selectedCarrier == 'Verizon'" class="m-4">
          <p class="text-sm text-portal-gray-700-base">
            Enter IMEI(s) and ICCID(s) separated by a comma. To activate many devices separate each IMEI/ICCID pair with
            a new line.
          </p>
        </div>
        <div v-if="activate && selectedCarrier == 'Verizon'" class="grid grid-cols-2 gap-1">
          <div class="relative mb-4 flex flex-col gap-1">
            <label class="text-lg font-semibold" for="iccids">IMEI, ICCIDs</label>
            <textarea
              id="iccids"
              v-model="iccids"
              class="form-input mx-2 rounded border px-2 py-1"
              placeholder="Enter IMEI, ICCIDs..."
              rows="10"
              type="text"
            />
          </div>
          <div class="flex flex-col">
            <div
              v-if="selectedCarrier && selectedCarrier == 'Verizon' && activate"
              class="relative mx-2 flex flex-col gap-1"
            >
              <label class="text-lg font-semibold" for="verizonPlanSearch">Verizon Plan</label>
              <input
                id="verizonPlanSearch"
                v-model="verizonPlanSearch"
                :disabled="!(selectedCompany && verizonInternalPlans.length)"
                autocomplete="off"
                class="form-input block w-full rounded border px-2 py-1"
                type="text"
                @blur="verizonHideDropdownAfterDelay"
                @focus="verizonShowPlanDropdown = true"
                @input="verizonSelectedPlan = null"
              />
              <ul
                v-if="verizonShowPlanDropdown"
                class="absolute left-0 top-full z-10 mt-1 max-h-32 w-full overflow-auto rounded border border-gray-300 bg-white"
              >
                <li
                  v-for="plan in verizonFilteredInternalPlans"
                  :key="plan.internalDataPlanId"
                  autocomplete="off"
                  class="w-full cursor-pointer px-2 py-1 hover:bg-portal-gray-100"
                  @click="verizonSelectPlan(plan)"
                >
                  {{ plan.name }}
                </li>
              </ul>
            </div>
            <div v-if="activate && selectedCarrier == 'Verizon'" class="relative mb-2 me-4 flex flex-col gap-1">
              <label class="text-lg font-semibold" for="costCenter">Cost Center Code</label>
              <input
                id="costCenter"
                v-model="costCenter"
                class="form-input mx-2 block w-full rounded border px-2 py-1"
                type="text"
              />
            </div>
          </div>
        </div>
        <div v-if="!activate || (activate && selectedCarrier == 'T-Mobile')" class="m-4">
          <p class="text-sm text-portal-gray-700-base">
            Enter ICCID(s) separated by a comma, space, or new line. You can also copy and paste a list of ICCIDs
            directly from a spreadsheet.
          </p>
        </div>
        <div v-if="!activate || (activate && selectedCarrier == 'T-Mobile')" class="grid grid-cols-2 gap-1">
          <div class="relative mb-4 flex flex-col gap-1">
            <label class="text-lg font-semibold" for="iccids">ICCIDs</label>
            <textarea
              id="iccids"
              v-model="iccids"
              class="form-input mx-2 rounded border px-2 py-1"
              placeholder="Enter ICCIDs..."
              rows="5"
              type="text"
            />
          </div>
          <div class="flex flex-col">
            <div v-if="selectedCarrier && selectedCarrier == 'T-Mobile'" class="relative mx-2 flex flex-col gap-1">
              <label class="text-lg font-semibold" for="t-mobilePlanSearch">T-Mobile Plan</label>
              <input
                id="t-mobilePlanSearch"
                v-model="tmobilePlanSearch"
                :disabled="!(selectedCompany && tmobileInternalPlans.length)"
                autocomplete="off"
                class="form-input block w-full rounded border px-2 py-1"
                type="text"
                @blur="tmobileHideDropdownAfterDelay"
                @focus="tmobileShowPlanDropdown = true"
                @input="tmobileSelectedPlan = null"
              />
              <ul
                v-if="tmobileShowPlanDropdown"
                class="absolute left-0 top-full z-10 mt-1 max-h-32 w-full overflow-auto rounded border border-gray-300 bg-white"
              >
                <li
                  v-for="plan in tmobileFilteredInternalPlans"
                  :key="plan.internalDataPlanId"
                  autocomplete="off"
                  class="w-full cursor-pointer px-2 py-1 hover:bg-portal-gray-100"
                  @click="tmobileSelectPlan(plan)"
                >
                  {{ plan.name }}
                </li>
              </ul>
            </div>

            <div v-if="selectedCarrier && selectedCarrier == 'Verizon'" class="relative mx-2 flex flex-col gap-1">
              <label class="text-lg font-semibold" for="verizonPlanSearch">Verizon Plan</label>
              <input
                id="verizonPlanSearch"
                v-model="verizonPlanSearch"
                :disabled="!(selectedCompany && verizonInternalPlans.length)"
                autocomplete="off"
                class="form-input block w-full rounded border px-2 py-1"
                type="text"
                @blur="verizonHideDropdownAfterDelay"
                @focus="verizonShowPlanDropdown = true"
                @input="verizonSelectedPlan = null"
              />
              <ul
                v-if="verizonShowPlanDropdown"
                class="absolute left-0 top-full z-10 mt-1 max-h-32 w-full overflow-auto rounded border border-gray-300 bg-white"
              >
                <li
                  v-for="plan in verizonFilteredInternalPlans"
                  :key="plan.internalDataPlanId"
                  autocomplete="off"
                  class="w-full cursor-pointer px-2 py-1 hover:bg-portal-gray-100"
                  @click="verizonSelectPlan(plan)"
                >
                  {{ plan.name }}
                </li>
              </ul>
            </div>
            <div v-if="tmobileSelectedPlan || verizonSelectedPlan" class="me-3 mt-3 flex flex-row justify-end">
              <label for="suspend" class="me-3"> Suspend Sims? </label>
              <input id="suspend" v-model="isSuspended" type="checkbox" />
            </div>
          </div>
        </div>

        <div class="mt-auto flex items-end justify-end gap-3 pb-3">
          <button
            class="mt-2 h-full rounded-md bg-rose-600 px-3 py-1 text-lg font-bold text-white transition-colors hover:bg-rose-500"
            @click="closeModal"
          >
            Cancel
          </button>
          <button
            class="mt-2 h-full rounded-md bg-portal-royal-700-base px-3 py-1 text-lg font-bold text-white transition-colors hover:bg-portal-royal-600"
            @click="confirmSelection"
          >
            Confirm Selection
          </button>
        </div>
        <Loader v-if="isLoading" class="absolute left-0 top-0 h-full w-full" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  Carrier,
  CarrierAccount,
  // DeviceStatus,
  DimCarrierAccountDataPlan,
  DimInternalDataPlan,
  Organization
} from "@prisma/client"

import Swal from "sweetalert2"
import CheckAddressForm from "./CheckAddressForm.vue"
// #region Plan search and selection
const isOpen = useState<boolean>("allocate-modal-open")
const carrierPlanSearch = ref<boolean>(false)
const selectedCarrier = ref("")
const showCarrierDropdown = ref(false)
const isSuspended = ref(false)
const activate = ref(false)
const refreshTable = useState<boolean>("refresh")
const iccids = ref<string>("")
const costCenter = ref<string>("")
const isReseller = useState<boolean>("isReseller")
const resellerId = useState<number>("resellerId")
const DeviceStatus = {
  active: "active",
  deactivated: "deactivated",
  suspended: "suspended",
  preActive: "preActive",
  pending: "pending",
  unknown: "unknown"
}
const addressValid = ref(false)
const addressData = ref({
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  zip: ""
})
// #region
interface Device {
  accountName: string
  billingCycleEndDate: string
  carrierInformations: CarrierInformation[]
  connected: boolean
  createdAt: string
  deviceIds: DeviceIdentifier[]
  extendedAttributes: ExtendedAttribute[]
  groupNames: string[]
  ipAddress: string
  lastActivationBy: string
  lastActivationDate: string
}

interface CarrierInformation {
  carrierName: string
  servicePlan: string
  state: string
}

interface DeviceIdentifier {
  id: string
  kind: string
}

interface ExtendedAttribute {
  key: string
  value?: string
}

const companies = ref<{ name: string; organizationId: number }[]>([])
const selectedCompany = ref<{ name: string; organizationId: number } | null>(null)
const companySearch = ref("")
const showCompanyDropdown = ref(false)
const isLoading = ref(false)
const filteredCompanies = computed(() => {
  const filtered = companies.value
    .filter((c) => c.name.toLowerCase().includes(companySearch.value.toLowerCase()))
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1
      } else if (a.name > b.name) {
        return 1
      } else {
        return 0
      }
    })

  if (filtered.length === 0) {
    filtered.push({ name: "No Companies Found", organizationId: 0 })
  }

  if ((tmobilePlanSearch.value || verizonPlanSearch.value) && selectedCompany.value?.name !== companySearch.value) {
    planSearch.value = ""
    tmobilePlanSearch.value = ""
    verizonPlanSearch.value = ""
    tmobileSelectedPlan.value = null
    verizonSelectedPlan.value = null
  }

  if (selectedCompany.value && selectedCompany.value?.name !== companySearch.value) {
    selectedCompany.value = null
  }

  return filtered
})

const internalPlans = ref<
  {
    name: string
    internalDataPlanId: number
    carrierAccountDataPlanId: number
  }[]
>([])

const planSearch = ref("")

// T-Mobile Plans
const tmobileInternalPlans = ref<
  {
    name: string
    internalDataPlanId: number
    carrierAccountDataPlanId: number
  }[]
>([])
const tmobileSelectedPlan = ref<{
  name: string
  internalDataPlanId: number
  carrierAccountDataPlanId: number
} | null>(null)
const tmobilePlanSearch = ref("")
const tmobileShowPlanDropdown = ref(false)
const tmobileFilteredInternalPlans = computed(() => {
  const filtered = tmobileInternalPlans.value
    .filter((p) => p.name.toLowerCase().includes(tmobilePlanSearch.value.toLowerCase()))
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1
      } else if (a.name > b.name) {
        return 1
      } else {
        return 0
      }
    })

  if (filtered.length === 0) {
    filtered.push({ name: "No Plans Found", internalDataPlanId: 0, carrierAccountDataPlanId: 0 })
  }

  return filtered
})

// Verizon Plans
const verizonInternalPlans = ref<
  {
    name: string
    internalDataPlanId: number
    carrierAccountDataPlanId: number
  }[]
>([])
const verizonSelectedPlan = ref<{
  name: string
  internalDataPlanId: number
  carrierAccountDataPlanId: number
} | null>(null)
const verizonPlanSearch = ref("")
const verizonShowPlanDropdown = ref(false)
const verizonFilteredInternalPlans = computed(() => {
  const filtered = verizonInternalPlans.value
    .filter((p) => p.name.toLowerCase().includes(verizonPlanSearch.value.toLowerCase()))
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1
      } else if (a.name > b.name) {
        return 1
      } else {
        return 0
      }
    })

  if (filtered.length === 0) {
    filtered.push({ name: "No Plans Found", internalDataPlanId: 0, carrierAccountDataPlanId: 0 })
  }

  return filtered
})

function closeModal() {
  isOpen.value = false
}
const route = useRoute()
onMounted(async () => {
  const res = await $fetch<Organization[]>("/api/organizations/all", {
    method: "GET"
  })

  if (res && isReseller.value == false) {
    companies.value = res
      .filter((org) => org.resellerId == null)
      .map((org) => ({
        name: org.name,
        organizationId: org.organizationId
      }))
  } else if (res && isReseller.value == true) {
    companies.value = res
      .filter((org) => org.resellerId == resellerId.value)
      .map((org) => ({
        name: org.name,
        organizationId: org.organizationId
      }))
  }
  const companyId = Number(route.params.organizationId)
  if (companyId) {
    const thisCompany = companies.value.find((c) => c.organizationId === companyId)
    if (thisCompany) {
      selectCompany(thisCompany)
    }
  }
})

const loadInternalPlans = async (organizationId: number) => {
  try {
    if (isReseller.value == true) {
      const res = await $fetch<
        (DimInternalDataPlan & {
          dataAllowance: number
          roamingDataAllowance: number
          internationalRoamingDataAllowance: number
          baseInternalDataPlan: DimInternalDataPlan & {
            carrierAccountDataPlan: DimCarrierAccountDataPlan & {
              carrierAccount: CarrierAccount & { carrier: Carrier }
            }
          }
        })[]
      >(`/api/organizations/${organizationId}/plans/reseller`, {
        method: "GET"
      })
      if (res && res.length) {
        internalPlans.value = res.map((plan) => ({
          name: plan.name,
          internalDataPlanId: plan.internalDataPlanId,
          carrierAccountDataPlanId: plan.baseInternalDataPlan.carrierAccountDataPlan.carrierAccountDataPlanId
        }))
        tmobileInternalPlans.value = res
          .filter((plan) => plan.baseInternalDataPlan.carrierAccountDataPlan.carrierAccount.carrier.name === "t-mobile")
          .map((plan) => ({
            name: plan.name,
            internalDataPlanId: plan.internalDataPlanId,
            carrierAccountDataPlanId: plan.baseInternalDataPlan.carrierAccountDataPlan.carrierAccountDataPlanId
          }))

        verizonInternalPlans.value = res
          .filter((plan) => plan.baseInternalDataPlan.carrierAccountDataPlan.carrierAccount.carrier.name === "verizon")
          .map((plan) => ({
            name: plan.name,
            internalDataPlanId: plan.internalDataPlanId,
            carrierAccountDataPlanId: plan.baseInternalDataPlan.carrierAccountDataPlan.carrierAccountDataPlanId
          }))
      } else {
        internalPlans.value = [{ name: "No Plans Available", internalDataPlanId: 0, carrierAccountDataPlanId: 0 }]
        planSearch.value = "No Plans Available"
      }
    } else if (isReseller.value == false) {
      const res = await $fetch<
        (DimInternalDataPlan & {
          dataAllowance: number
          roamingDataAllowance: number
          internationalRoamingDataAllowance: number
          carrierAccountDataPlan: DimCarrierAccountDataPlan & { carrierAccount: CarrierAccount & { carrier: Carrier } }
        })[]
      >(`/api/organizations/${organizationId}/plans`, {
        method: "GET"
      })
      if (res && res.length) {
        internalPlans.value = res.map((plan) => ({
          name: plan.name,
          internalDataPlanId: plan.internalDataPlanId,
          carrierAccountDataPlanId: plan.carrierAccountDataPlan.carrierAccountDataPlanId
        }))
        tmobileInternalPlans.value = res
          .filter((plan) => plan.carrierAccountDataPlan.carrierAccount.carrier.name === "t-mobile")
          .map((plan) => ({
            name: plan.name,
            internalDataPlanId: plan.internalDataPlanId,
            carrierAccountDataPlanId: plan.carrierAccountDataPlan.carrierAccountDataPlanId
          }))

        verizonInternalPlans.value = res
          .filter((plan) => plan.carrierAccountDataPlan.carrierAccount.carrier.name === "verizon")
          .map((plan) => ({
            name: plan.name,
            internalDataPlanId: plan.internalDataPlanId,
            carrierAccountDataPlanId: plan.carrierAccountDataPlan.carrierAccountDataPlanId
          }))
      } else {
        internalPlans.value = [{ name: "No Plans Available", internalDataPlanId: 0, carrierAccountDataPlanId: 0 }]
        planSearch.value = "No Plans Available"
      }
    }
  } catch (error) {
    console.error(error)
  }
}

const selectCompany = (company: { name: string; organizationId: number }) => {
  selectedCompany.value = company
  companySearch.value = company.name
  showCompanyDropdown.value = false
  loadInternalPlans(company.organizationId)
}
const selectCarrier = (carrier: string) => {
  selectedCarrier.value = carrier
  carrierPlanSearch.value = true
  showCarrierDropdown.value = false
}

const tmobileSelectPlan = (plan: { name: string; internalDataPlanId: number; carrierAccountDataPlanId: number }) => {
  tmobileSelectedPlan.value = plan

  tmobilePlanSearch.value = plan.name
  tmobileShowPlanDropdown.value = false
}

const verizonSelectPlan = (plan: { name: string; internalDataPlanId: number; carrierAccountDataPlanId: number }) => {
  verizonSelectedPlan.value = plan
  isFWAPlan(plan.internalDataPlanId)
  verizonPlanSearch.value = plan.name
  verizonShowPlanDropdown.value = false
}
const isFWA = ref(false)
const isFWAPlan = async (internalDataPlanId: number) => {
  try {
    const res = await $fetch<DimInternalDataPlan & { carrierAccountDataPlan: DimCarrierAccountDataPlan }>(
      `/api/internal-plans/${internalDataPlanId}`,
      {
        method: "GET"
      }
    )
    const fwaIds = [70, 71, 72, 73]
    if (fwaIds.includes(res.carrierAccountDataPlan.carrierAccountDataPlanId)) {
      isFWA.value = true
    } else {
      isFWA.value = false
    }
  } catch (error) {
    console.error(error)
  }
}

const companyHideDropdownAfterDelay = () => {
  setTimeout(() => {
    showCompanyDropdown.value = false
  }, 150)
}
const carrierHideDropdownAfterDelay = () => {
  setTimeout(() => {
    showCarrierDropdown.value = false
  }, 150)
}

const tmobileHideDropdownAfterDelay = () => {
  setTimeout(() => {
    tmobileShowPlanDropdown.value = false
  }, 150)
}

const verizonHideDropdownAfterDelay = () => {
  setTimeout(() => {
    verizonShowPlanDropdown.value = false
  }, 150)
}
function mapResellerData(
  resellerData: DimInternalDataPlan & {
    baseInternalDataPlan: DimInternalDataPlan & {
      carrierAccountDataPlan: DimCarrierAccountDataPlan & { carrierAccount: CarrierAccount & { carrier: Carrier } }
    }
  }
) {
  const { baseInternalDataPlan, ...rest } = resellerData
  const mappedData: DimInternalDataPlan & {
    carrierAccountDataPlan: DimCarrierAccountDataPlan & { carrierAccount: CarrierAccount & { carrier: Carrier } }
  } = {
    ...rest,
    // carrierAccountDataPlanId: baseInternalDataPlan.carrierAccountDataPlanId,
    carrierAccountDataPlan: baseInternalDataPlan.carrierAccountDataPlan
  }
  return mappedData
}
// #endregion
const confirmSelection = async () => {
  // If a device is to be activated to an FWA plan it must have a valid address
  if (activate.value && isFWA.value && !addressValid.value) {
    Swal.fire({
      title: "Error",
      text: "Please check the address before continuing.",
      icon: "error",
      iconColor: "red",
      showConfirmButton: true,
      toast: false
    })
    return
  }
  if (activate.value && selectedCarrier.value == "Verizon") {
    try {
      const hasComma = iccids.value.includes(",")
      if (!hasComma || iccids.value.endsWith("\n") || !selectedCompany.value || !verizonSelectedPlan.value) {
        Swal.fire({
          title: "Error",
          text: "Please select a company, plan and at least one comma separated IMEI/ICCID pair. Do you have trailing whitespace or an empty line?",
          icon: "error",
          iconColor: "red",
          showConfirmButton: true,
          toast: false
        })
        return
      }
      // Take the inputted IMEI/ICCID pairs and split them into an array of objects

      let imeiIccidsArray = iccids.value
        .split("\n")
        .map((imeiIccid) => {
          const [imei, iccid] = imeiIccid.split(",")
          return {
            IMEI: imei.trim(),
            ICCID: iccid.trim()
          }
        })
        .filter((imeiIccidPair) => imeiIccidPair.IMEI && imeiIccidPair.ICCID)
      if (imeiIccidsArray.length === 0) {
        Swal.fire({
          title: "Error",
          text: "Please enter at least one IMEI/ICCID pair.",
          icon: "error",
          iconColor: "red",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 3000,
          toast: true,
          position: "top-end"
        })
        return
      }
      isLoading.value = true
      // Get the internal data plan from the database
      let internalDataPlan:
        | (DimInternalDataPlan & {
            carrierAccountDataPlan: DimCarrierAccountDataPlan & {
              carrierAccount: CarrierAccount & { carrier: Carrier }
            }
          })
        | null = null
      if (isReseller.value) {
        const resellerInternalDataPlan = await $fetch<
          DimInternalDataPlan & {
            baseInternalDataPlan: DimInternalDataPlan & {
              carrierAccountDataPlan: DimCarrierAccountDataPlan & {
                carrierAccount: CarrierAccount & { carrier: Carrier }
              }
            }
          }
        >(`/api/internal-plans/${verizonSelectedPlan.value!.internalDataPlanId}`, {
          method: "GET"
        })
        internalDataPlan = mapResellerData(resellerInternalDataPlan)
      } else {
        internalDataPlan = await $fetch<
          DimInternalDataPlan & {
            carrierAccountDataPlan: DimCarrierAccountDataPlan & {
              carrierAccount: CarrierAccount & { carrier: Carrier }
            }
          }
        >(`/api/internal-plans/${verizonSelectedPlan.value!.internalDataPlanId}`, {
          method: "GET"
        })
      }
      // Check and see if the devices have been previously added to the database
      const knownDevices = []
      const unknownDevices = []
      for (const imeiIccid of imeiIccidsArray) {
        const device = await $fetch(`/api/devices/${imeiIccid.ICCID}`, {
          method: "GET"
        })
        if (device) {
          knownDevices.push(device.iccid)
        } else {
          unknownDevices.push(imeiIccid)
        }
      }
      if (knownDevices.length) {
        isLoading.value = false
        Swal.fire({
          title: "Error",
          html: `<div style="white-space: pre-line; max-height: 400px; overflow-y: scrollable">The following ICCIDs are already in the database and cannot be activated:\n
            ${knownDevices.join("\n")}</div>`,
          icon: "error",
          iconColor: "red",
          showConfirmButton: true,
          toast: false
        })
        return
      }
      let filteredUnknownDevices: { ICCID: string; IMEI: string }[] = []
      // Check and see if the unknown devices are in thingspace
      if (unknownDevices.length) {
        const deviceList = await $fetch<Device[]>(`/api/carriers/verizon/check-device-info`, {
          method: "POST",
          body: unknownDevices.map((device) => ({
            ICCID: device.ICCID,
            internalDataPlan: verizonSelectedPlan.value
          }))
        })
        const devices = deviceList
        if (devices && devices.length) {
          for (const device of devices) {
            const iccid = device.deviceIds.find((id) => id.kind.toLowerCase() === "iccid")?.id
            if (costCenter.value) {
              await $fetch(`/api/devices/${iccid}/cost-center`, {
                method: "PUT",
                body: {
                  costCenter: costCenter.value,
                  carrierAccountId: internalDataPlan!.carrierAccountDataPlan.carrierAccountId
                }
              })
            }
          }
          const activeIccids = devices.map(
            (device) => device.deviceIds.find((id) => id.kind.toLowerCase() === "iccid")?.id
          )
          filteredUnknownDevices = unknownDevices.filter((device) => !activeIccids.includes(device.ICCID))
          const statusMap: { [key: string]: string } = {
            active: DeviceStatus.active,
            suspend: DeviceStatus.suspended,
            deactive: DeviceStatus.deactivated
          }
          const getStatus = (status: string): string => {
            if (status.startsWith("pending")) {
              return DeviceStatus.pending
            }
            return statusMap[status] || DeviceStatus.unknown
          }
          try {
            // Save the active in Thingspace devices to our database
            const res = await $fetch("/api/devices", {
              method: "POST",
              body: devices.map((device) => ({
                iccid: device.deviceIds.find((id) => id.kind.toLowerCase() === "iccid")?.id,
                msisdn: device.deviceIds.find((id) => id.kind === "msisdn")?.id ?? "",
                imei: device.deviceIds.find((id) => id.kind === "imei")?.id ?? "",
                ipAddress: device.ipAddress ?? "",
                label: costCenter.value,
                status: getStatus(device.carrierInformations[0].state),
                activatedAt: new Date(device.lastActivationDate),
                allocatedAt: new Date(),
                lastSyncedAt: new Date(),
                carrierAccountDataPlanId: internalDataPlan!.carrierAccountDataPlan
                  ? internalDataPlan!.carrierAccountDataPlan.carrierAccountDataPlanId
                  : internalDataPlan!.carrierAccountDataPlanId,
                organizationId: selectedCompany.value!.organizationId,
                internalDataPlanId: verizonSelectedPlan.value!.internalDataPlanId
              }))
            })

            if (devices.length == unknownDevices.length && res && res.count == unknownDevices.length) {
              isLoading.value = false
              if (route.params.organizationId) {
                refreshTable.value = true
              }
              Swal.fire({
                title: "Success",
                html: `<div style="white-space: pre-line; max-height: 400px; overflow-y: scrollable">Successfully activated SIMs.</div>`,
                icon: "success",
                iconColor: "green",
                showConfirmButton: true,
                toast: false
              }).then(() => {
                closeModal()
              })
              return
            }
          } catch (error) {
            isLoading.value = false
            Swal.fire({
              title: "Error",
              html: `<div style="white-space: pre-line; max-height: 400px; overflow-y: scrollable">Error activating SIMs: ${error}</div>`,
              icon: "error",
              iconColor: "red",
              showConfirmButton: false,
              showCancelButton: true,
              toast: false
            })
            return
          }
        }
      }
      if (filteredUnknownDevices && filteredUnknownDevices.length) {
        imeiIccidsArray = filteredUnknownDevices
      }
      // Send the IMEI/ICCID pairs to the carrier to activate
      let allocateResult: any = null
      // If the activation is FWA send the address to the carrier
      if (isFWA.value && addressValid.value) {
        allocateResult = await $fetch("/api/carriers/verizon/connections/activate-fwa", {
          method: "POST",
          body: imeiIccidsArray.map((imeiIccid) => ({
            imei: imeiIccid.IMEI,
            iccid: imeiIccid.ICCID,
            organizationId: (selectedCompany.value as Organization)!.organizationId,
            internalDataPlanId: (verizonSelectedPlan.value as DimInternalDataPlan)!.internalDataPlanId,
            carrierAccountDataPlanId: internalDataPlan!.carrierAccountDataPlan
              ? internalDataPlan!.carrierAccountDataPlan.carrierAccountDataPlanId
              : internalDataPlan!.carrierAccountDataPlanId,
            costCenter: costCenter.value,
            toSuspend: isSuspended.value,
            address: addressData.value
          }))
        })
      } else {
        allocateResult = await $fetch(`/api/carriers/verizon/connections/activate`, {
          method: "POST",
          body: imeiIccidsArray.map((imeiIccid) => ({
            imei: imeiIccid.IMEI,
            iccid: imeiIccid.ICCID,
            organizationId: selectedCompany.value!.organizationId,
            internalDataPlanId: verizonSelectedPlan.value!.internalDataPlanId,
            carrierAccountDataPlanId: internalDataPlan!.carrierAccountDataPlan
              ? internalDataPlan!.carrierAccountDataPlan.carrierAccountDataPlanId
              : internalDataPlan!.carrierAccountDataPlanId,
            costCenter: costCenter.value,
            toSuspend: isSuspended.value
          }))
        })
      }
      if (allocateResult !== null && allocateResult.status == 200) {
        isLoading.value = false
        if (route.params.organizationId) {
          refreshTable.value = true
        }
        Swal.fire({
          title: "Success",
          html: `<div style="white-space: pre-line; max-height: 400px; overflow-y: scrollable">Successfully activated SIMs.${
            allocateResult.errors && allocateResult.errors.length ? `\nErrors:\n${allocateResult.errors.join("")}` : ""
          }</div>`,
          icon: "success",
          iconColor: "green",
          showConfirmButton: true,
          toast: false
        }).then(() => {
          closeModal()
        })
      } else {
        isLoading.value = false
        Swal.fire({
          title: "Error",
          html: `<div style="white-space: pre-line; max-height: 400px; overflow-y: scrollable">Error activating SIMs.\nErrors:\n${
            allocateResult.errors ? JSON.stringify(allocateResult.errors.map((e: any) => e.name)) : ""
          }</div>`,
          icon: "error",
          iconColor: "red",
          showConfirmButton: false,
          showCancelButton: true,
          toast: false
        })
      }
    } catch (error) {
      isLoading.value = false
      Swal.fire({
        title: "Error",
        html: `<div style="white-space: pre-line; max-height: 400px; overflow-y: scrollable">Error Activating: ${error}</div>`,
        icon: "error",
        iconColor: "red",
        showConfirmButton: false,
        showCancelButton: true,
        toast: false
      })
      console.error(error)
    }
  } else if (activate.value && selectedCarrier.value == "T-Mobile") {
    try {
      if (
        iccids.value.length === 0 ||
        !selectedCompany.value ||
        (!tmobileSelectedPlan.value && !verizonSelectedPlan.value)
      ) {
        Swal.fire({
          title: "Error",
          text: "Please select a company, plan and at least one ICCID.",
          icon: "error",
          iconColor: "red",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 3000,
          toast: true,
          position: "top-end"
        })
        return
      }
      isLoading.value = true
      let unknownDevices: any[] = []
      const knownDevices = []
      // Split the inputted iccids into an array and remove any trailing 'f' characters
      const iccidsArray = iccids.value
        .split(/\n|, |,|\s+/)
        .filter((iccid) => iccid.trim() !== "")
        .map((iccid) => (iccid.toLowerCase().endsWith("f") ? iccid.slice(0, -1) : iccid))
      // Seeing if any existing sims were mistakenly entered
      for (const iccid of iccidsArray) {
        const device = await $fetch(`/api/devices/${iccid}`, {
          method: "GET"
        })
        // If the device is not in the database, add it to the unknownDevices array
        if (!device) {
          unknownDevices.push(iccid)
        } else {
          // If the device is in the database, add it to the knownDevices array
          knownDevices.push(device.iccid)
        }
        // If any of the devices already exist we kick them a warning
        if (knownDevices.length) {
          isLoading.value = false
          Swal.fire({
            title: "Error",
            html: `<div style="white-space: pre-line; max-height: 400px; overflow-y: scrollable">The following ICCIDs are already in the database and cannot be activated:\n
              ${knownDevices.join("\n")}</div>`,
            icon: "error",
            iconColor: "red",
            showConfirmButton: true,
            toast: false
          })
          return
        }
        // Checking T-Mobile to see if the devices exist in their portal
        if (unknownDevices.length) {
          const devices = await $fetch<{ tmobileKnownDevices: string[]; tmobileUnknownDevices: string[] }>(
            "/api/carriers/t-mobile/retrieve-device-list",
            {
              method: "POST",
              body: unknownDevices
            }
          )
          // If the carrier returns known devices, save them to the database and add them to the knownDevices array
          if (devices && devices.tmobileKnownDevices.length) {
            try {
              await $fetch("/api/devices", {
                method: "POST",
                body: devices.tmobileKnownDevices.map((iccid: any) => ({
                  iccid,
                  carrierAccountDataPlanId: tmobileSelectedPlan.value?.carrierAccountDataPlanId,
                  organizationId: selectedCompany.value!.organizationId,
                  internalDataPlanId: tmobileSelectedPlan.value!.internalDataPlanId
                }))
              })
              // We then filter out the now saved known devices from the unknownDevices array
              unknownDevices = unknownDevices.filter((iccid) => !devices.tmobileKnownDevices.includes(iccid))
            } catch (error) {
              console.error(error)
            }
          }
          // Activating unknown sims with the activate endpoint
          const res = await $fetch(`/api/carriers/t-mobile/connections/activate`, {
            method: "POST",
            body: unknownDevices.map((iccid) => ({
              iccid,
              organizationId: selectedCompany.value!.organizationId,
              carrierAccountDataPlanId: tmobileSelectedPlan.value!.carrierAccountDataPlanId,
              internalDataPlanId: tmobileSelectedPlan.value!.internalDataPlanId
            }))
          })
          if (res.status == 200) {
            isLoading.value = false
            if (route.params.organizationId) {
              refreshTable.value = true
            }
            Swal.fire({
              title: "Success",
              html: `<div style="white-space: pre-line; max-height: 400px; overflow-y: scrollable">Successfully activated SIMs.${
                res.errors.length ? `\nErrors:\n${res.errors.join("")}` : ""
              }</div>`,
              icon: "success",
              iconColor: "green",
              showConfirmButton: true,
              toast: false
            }).then(() => {
              closeModal()
            })
          } else {
            isLoading.value = false
            Swal.fire({
              title: "Error",
              html: `<div style="white-space: pre-line; max-height: 400px; overflow-y: scrollable">Error activating SIMs.\nErrors:\n${res.errors.join(
                ""
              )}</div>`,
              icon: "error",
              iconColor: "red",
              showConfirmButton: false,
              showCancelButton: true,
              toast: false
            })
          }
        }
      }
    } catch (error) {
      isLoading.value = false
      Swal.fire({
        title: "Error",
        html: `<div style="white-space: pre-line; max-height: 400px; overflow-y: scrollable">Error activating SIMs.</div>`,
        icon: "error",
        iconColor: "red",
        showConfirmButton: false,
        showCancelButton: true,
        toast: false
      })
      console.error(error)
    }
  } else if (!activate.value) {
    try {
      if (
        iccids.value.length === 0 ||
        !selectedCompany.value ||
        (!tmobileSelectedPlan.value && !verizonSelectedPlan.value)
      ) {
        Swal.fire({
          title: "Error",
          text: "Please select a company, plan and at least one ICCID.",
          icon: "error",
          iconColor: "red",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 3000,
          toast: true,
          position: "top-end"
        })
        return
      }
      // Split the inputted iccids into an array and remove any trailing 'f' characters
      const iccidsArray = iccids.value
        .split(/\n|, |,|\s+/)
        .filter((iccid) => iccid.trim() !== "")
        .map((iccid) => (iccid.toLowerCase().endsWith("f") ? iccid.slice(0, -1) : iccid))
      isLoading.value = true
      let unknownDevices = []
      const knownDevices = []
      const deactivatedDevices = []
      const suspendedVerizonDevices = []
      const errors = []

      // Checking inputted iccids against devices from the database
      for (const iccid of iccidsArray) {
        const device = await $fetch(`/api/devices/${iccid}`, {
          method: "GET"
        })
        // If the device is not in the database, add it to the unknownDevices array
        if (!device) {
          unknownDevices.push(iccid)
        } else {
          // If the device is in the database, add it to the knownDevices array
          knownDevices.push(device.iccid)
          // If the known device is deactivated, add it to the deactivatedDevices array
          if (device.status == "deactivated") {
            deactivatedDevices.push(device)
          }
          // If the known device is suspended and they are trying to allocate it to a new plan, add it to the suspendedVerizonDevices array
          if (
            device.status == "suspended" &&
            selectedCarrier.value == "Verizon" &&
            verizonSelectedPlan.value!.carrierAccountDataPlanId != device.internalDataPlan.carrierAccountDataPlanId
          ) {
            suspendedVerizonDevices.push(device)
          }
        }
      }
      // If you attempt to allocate a suspended device to a new plan, stop! Can only allocate to active Verizon sims
      if (suspendedVerizonDevices.length) {
        isLoading.value = false
        Swal.fire({
          title: "Error",
          html: `<div style="white-space: pre-line; max-height: 400px; overflow-y: scrollable">The following ICCIDs are suspended and cannot be allocated to a new carrier plan:\n
            ${suspendedVerizonDevices.map((device) => device.iccid).join("\n")}</div>`,
          icon: "error",
          iconColor: "red",
          showConfirmButton: true,
          toast: false
        })
        return
      }
      // If there is a known, but deactivated sim, stop! Can only allocate to active sims
      if (deactivatedDevices.length) {
        isLoading.value = false
        Swal.fire({
          title: "Error",
          html: `<div style="white-space: pre-line; max-height: 400px; overflow-y: scrollable">The following ICCIDs are deactivated and cannot be allocated:\n
            ${deactivatedDevices.map((device) => device.iccid).join("\n")}</div>`,
          icon: "error",
          iconColor: "red",
          showConfirmButton: true,
          toast: false
        })
        return
      }

      // If there are unknown devices, send them to the carrier to check if they are in thingspace
      if (unknownDevices.length && selectedCarrier.value === "Verizon") {
        const res = await $fetch<{ verizonKnownDevices: string[]; verizonUnknownDevices: string[] }>(
          "/api/carriers/verizon/retrieve-device-list",
          {
            method: "POST",
            body: unknownDevices
          }
        )
        if (!res) {
          isLoading.value = false
          Swal.fire({
            title: "Error",
            text: "Error retrieving device list from Verizon.",
            icon: "error",
            iconColor: "red",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 3000,
            toast: true,
            position: "top-end"
          })
          return
        }
        // If the carrier returns known devices, save them to the database and add them to the knownDevices array
        if (res && res.verizonKnownDevices.length) {
          try {
            await $fetch("/api/devices", {
              method: "POST",
              body: res.verizonKnownDevices.map((iccid) => ({
                iccid,
                carrierAccountDataPlanId: verizonSelectedPlan.value?.carrierAccountDataPlanId,
                organizationId: selectedCompany.value!.organizationId,
                internalDataPlanId: verizonSelectedPlan.value!.internalDataPlanId
              }))
            })
            knownDevices.push(res.verizonKnownDevices)
            // Remove Verizon known devices from the unknownDevices array after they are saved to the db
            unknownDevices = unknownDevices.filter((iccid) => !res.verizonKnownDevices.includes(iccid))
          } catch (error) {
            errors.push(error)
            console.error(error)
          }
        }
        // Handle unknown T-Mobile Devices -- Temporary error notice for now
      } else if (unknownDevices.length && selectedCarrier.value === "T-Mobile") {
        isLoading.value = false
        Swal.fire({
          title: "Error",
          text: "An inputted device is unknown and must be activated as a new device. Please check the 'Activate' box and try again.",
          icon: "error",
          iconColor: "red",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 3000,
          toast: true,
          position: "top-end"
        })
        return
      }
      // Then put the knownDevices into the normal allocate flow
      const allocateResult = await $fetch<{ status: number; response: string; errors: any[] }>(
        "/api/connections/allocate",
        {
          method: "PUT",
          body: {
            iccids: knownDevices,
            organizationId: selectedCompany.value!.organizationId,
            internalDataPlanId: tmobileSelectedPlan.value
              ? tmobileSelectedPlan.value.internalDataPlanId
              : verizonSelectedPlan.value!.internalDataPlanId,
            isSuspended: isSuspended.value
          }
        }
      )
      // If the allocation is successful and at least some of the devices are known, show a success message
      if (allocateResult.status == 200 && iccidsArray.length != unknownDevices.length) {
        isLoading.value = false
        if (route.params.organizationId) {
          refreshTable.value = true
        }
        Swal.fire({
          title: "Success",
          html: `<div style="white-space: pre-line; max-height: 400px; overflow-y: scrollable">
               Successfully allocated SIM(s). ${
                 unknownDevices.length
                   ? `The following ICCIDs are unknown and must be activated as new devices with IMEIs:\n
              ${unknownDevices.join("\n")}`
                   : ``
               }
              ${allocateResult.errors.length ? `Errors:\n${allocateResult.errors.join("\n")}` : ``}
              ${errors.length ? `Errors:\n${errors.join("\n")}` : ``}
            </div>`,
          icon: "success",
          iconColor: "green",
          showConfirmButton: true,
          toast: false
        }).then(() => {
          closeModal()
        })
      } else {
        // Allocation failed, show an error message
        isLoading.value = false
        Swal.fire({
          title: "Error",
          html: `<div style="white-space: pre-line; max-height: 400px; overflow-y: scrollable">Error allocating SIMs.\n${
            unknownDevices.length
              ? `The following ICCIDs are unknown and must be activated as new devices with IMEIs:\n
              ${unknownDevices.join("\n")}`
              : ``
          }${allocateResult.errors.length > 0 ? `\nErrors:\n${allocateResult.errors.join("\n")}` : ``}
            ${errors.length ? `Errors:\n${errors.join("\n")}` : ``}</div>`,
          icon: "error",
          iconColor: "red",
          showConfirmButton: false,
          showCancelButton: true,
          toast: false
        })
      }
    } catch (error) {
      isLoading.value = false
      console.error(error)
    }
  }
}
</script>

<style scoped>
.form-input {
  @apply border-portal-royal-700-base px-2 py-1 text-base leading-5 outline-none focus:border-portal-royal-700-base focus:ring-2 focus:ring-portal-royal-700-base;
}
</style>
