<template>
  <div class="flex flex-wrap items-center justify-center gap-2 text-center font-bold">
    <div class="relative flex flex-col gap-1 text-center">
      <span class="text-xs">Usage Type</span>
      <button
        aria-expanded="true"
        aria-haspopup="true"
        class="inline-flex min-w-max items-center justify-center rounded bg-portal-royal-700-base px-3 py-1 text-white hover:bg-portal-royal-600"
        type="button"
        @click.stop="dropdownOpen = !dropdownOpen"
      >
        {{ selectedUsageType }}
      </button>
      <div
        v-if="dropdownOpen"
        ref="dropdownElement"
        aria-labelledby="usage-type"
        aria-orientation="vertical"
        class="absolute right-0 top-full z-20 min-w-max origin-top-right transform cursor-pointer overflow-hidden rounded border border-portal-gray-300 bg-white font-normal text-portal-gray-800 shadow-xl"
        role="menu"
      >
        <li
          v-for="usageType in usageTypes"
          :key="usageType"
          :class="{
            'bg-portal-gray-100 text-portal-gray-700-base hover:!bg-portal-gray-200 hover:text-portal-gray-700-base':
              usageType === usageTypes.find((type) => type === selectedUsageType)
          }"
          class="block px-2 py-2 text-center hover:bg-portal-royal-700-base hover:text-white"
          href="#"
          role="menuitem"
          @click="setUsageType(usageType)"
        >
          {{ usageType }}
        </li>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { UsageType } from "~/interfaces/UsageType"

const dropdownOpen = ref(false)
const dropdownElement = ref<Element | null>(null)

const selectedUsageType = useState<UsageType>("usageType")
const usageTypes = ref<UsageType[]>([UsageType.Metered, UsageType.Unlimited, UsageType.All])

function setUsageType(usageType: UsageType) {
  selectedUsageType.value = usageType
  localStorage.setItem("usageType", String(usageType))
  dropdownOpen.value = false
}

watch(dropdownOpen, async (newValue, oldValue) => {
  if (newValue !== oldValue) {
    if (newValue) {
      // Dropdown is open, add the click listener
      window.addEventListener("click", handleClickOutside)
    } else {
      // Dropdown is closed, remove the click listener
      window.removeEventListener("click", handleClickOutside)
    }
  }
})

function handleClickOutside(event: MouseEvent) {
  if (dropdownElement.value && !dropdownElement.value.contains(event.target as Node) && dropdownOpen.value) {
    dropdownOpen.value = false
  }
}

onUnmounted(() => {
  window.removeEventListener("click", handleClickOutside)
})
</script>

<style scoped></style>
